export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';

export const LOAD_LISTINGS_SUCCESS = 'LOAD_LISTINGS_SUCCESS';
export const UPDATE_LISTINGS_SUCCESS = 'UPDATE_LISTINGS_SUCCESS';

export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS';
export const UPDATE_TRANSACTIONS_SUCCESS = 'UPDATE_TRANSACTIONS_SUCCESS';

export const LOAD_REVIEWS_SUCCESS = 'LOAD_REVIEWS_SUCCESS';
export const UPDATE_REVIEWS_SUCCESS = 'UPDATE_REVIEWS_SUCCESS';

export const LOAD_MESSAGES_SUCCESS = 'LOAD_MESSAGES_SUCCESS';
export const UPDATE_MESSAGES_SUCCESS = 'UPDATE_MESSAGES_SUCCESS';

export const LOAD_IMAGES_SUCCESS = 'LOAD_IMAGES_SUCCESS';
export const UPDATE_IMAGES_SUCCESS = 'UPDATE_IMAGES_SUCCESS';